import React from 'react';


export default class Projects extends React.Component{

    public render(): React.ReactNode {
        return (
            <div>
      <h1>WIP</h1>
      {/* Contenu de la page d'accueil */}
    </div>
        );
    }
  }